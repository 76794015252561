.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.green-pin {
  list-style-image: url('./assets/images/mapPinGreen.svg');
}

.gold-pin {
  list-style-image: url('./assets/images/mapPinGold.svg');
}

/* collapsible section in PP */

.content {
  padding: 20px !important;
  border: 2px solid rgb(0, 0, 0);
}

.collapsible .content {
  padding-top: 0px;
   /* background-color: rgb(240, 240, 240);
  font-size: 14px; */
}
.collapsible .header {
  padding-top: 0px;
  /* background-color: rgb(200, 200, 200);
  
  cursor: pointer;
  display: flex;
  justify-content: space-between; 
  border: 2px solid rgb(0, 0, 0);*/
}
.collapsible .header .title {
  /* font-weight: 400; */
  display: block;
}
.preferences label {
  display: block;
  /* padding-bottom: 4px; */
}