@tailwind base;
@tailwind components;
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@100;400;500;700&display=swap");

html, body {
  background-color: #0F1933;
  font-family: "Arimo", sans-serif;
}

h1 {
  font-family: 'Libre Franklin', sans-serif;
}
.text-lg {
  font-family: 'Libre Franklin', sans-serif;
}


p {
  font-family: "Arimo", sans-serif;
}

.radio {
  width: 22px;
  height: 22px;
  border-radius: 20px;
  background-color: #fff;
  border: 6px solid #fec209;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    input[type="radio"]:checked:after {
      width: 28px;
      height: 28px;
      border-radius: 28px;
      position: relative;
      background-color: #fff;
      content: "";
      display: inline-block;
      visibility: visible;
      border: 6px solid #fec209;
      left: -1px;
      top: -1px;
    }
  }
}

input[type="radio"]:checked:after {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 6px solid #fec209;
  left: -1px;
  top: -1px;
}

.search{
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
}

.search input{
  padding: 0.5rem 1rem;
  width: 100%;
}
@tailwind utilities;

.sk-wave-rect {
  background-color: #fec209 !important;
}
a {
  color: #fec209;
}

.bg-white {
  background-color: #fff;
}
.text-blue-theme {
  --tw-text-opacity:1;
  color:#0f1933;
}
.text-black-theme {
  --tw-text-opacity:1;
  color:#000000;
}
.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}
.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}
.bg-yellow-theme {
  --tw-bg-opacity: 1;
  background-color: #fec209;
}
.bg-yellow-2-theme {
  background-color: #ffe266;
}
.bg-yellow-3-theme {
  background-color: #fff4cb;
}
.bg-yellow-4-theme {
  transform: translate(-1ch, 6mm);
  background-color: #ffce00;
}
.border-red-600 {
  --tw-border-opacity: 1;
  border-color: #dc2626;
}
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: #d1d5db;
}

.image-container {
    width: 85px;
    height: 85px;
    border-radius: 100%;
}

.unify-table-cell-first {
  padding-right: 1rem !important;
  padding-bottom: 1rem !important;
}

.unify-table-cell {
  padding-bottom: 1rem !important;
}

.unify-table-header {
  text-align: left;
  padding-left: 0px !important;
  padding-right: 1rem !important;
  margin: 0px !important;
}

